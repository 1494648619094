import React from 'react';
import { useModal } from '../ContactForm';
import { FooterContactBox } from '../FooterContactBox';
import { HRMSBanner } from './HRMSBanner'
import { HrmsSolutions } from './HrmsSolutions'
import { Organizations } from './Organizations'

export const HRMSPage = () => {
  const { modalAction } = useModal();
  return (
      <div className=''>
          <HRMSBanner />
          <div className='bg-[#16072D] text-white'>
              
              <div className='leading-10 py-12 px-6 lg:px-10'>
                  <h3 className='text-[30px] font-[600] lg:text-center'>Customized HRMS solutions <span className='block md:inline text-[#985CF1]'>for any size organizations</span></h3>
                  <p className='lg:text-center text-[16px] font-[400] mt-5'>From when employees step up to when they step down, our HRMS software stores every detail and makes management convenient.</p>
                 <p className='border-0 border-b-[2px] border-dashed border-[#FFFFFF] pt-10 lg:px-3'></p>
              </div>

              <HrmsSolutions />
          </div>
      <Organizations />
      <FooterContactBox >
        <div className='p-6 lg:p-0'>
          <h3 className='text-[25px] lg:text-[38px] lg:w-4/5 2xl:w-auto font-[700] tracking-tighter'>Single and reliable easy to use HR toolkit for <span className='text-[#985CF1]'>businesses with end-to-end encryption for security.</span></h3>
          <button className='bg-[#985CF1] text-white py-[8px] px-[20px] lg:py-[11px] lg:px-[25px] rounded-[48px] mt-10 2xl:mt-[70px]' onClick={modalAction}>Learn more</button>
        </div>
      </FooterContactBox>
          
    </div>
  )
}
