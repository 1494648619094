import hrmsPayrollImage from "../images/hrmsPayrollImage.svg";
import hrmsAttendance from "../images/hrmsAttendance.svg";
import hrmsOnboard from "../images/hrmsOnboard.svg";
import hrmsUpSkill from "../images/hrmsUpSkill.svg";
import hrmsVerifyImage from "../images/hrmsVerifyImage.svg";

import flex from "../images/Flex.svg";
import foxConn from "../images/Fox_con.svg";
import jio from "../images/Jio.svg";
import jabil from "../images/Jabil.svg";
import toshiba from "../images/Toshiba.svg";




export const bannerContent = {
    title: {
        first: "Smart and secure HR toolkit",
        second: "for more organized workforce management"
    },
    paraLine: "We assist you in hiring smarter, engaging smarter, and working smarter. Manage the modern workforce with the aid of HRMS software.",
    button: "Book a Demo"
};

export const solutions = [
    {
        image: hrmsPayrollImage,
        serialNumber: "01",
        title: "Payroll",
        paraLine: "With our HRMS, connect payroll to personal records for",
        listData: [
            "Calculating leaves and deductions",
            "Processing and calculating salaries",
            "Managing payroll taxes and returning tax forms",
            "Paycheck sheets and reports"
        ],
        button: "Learn more"
    },
    {
        image: hrmsVerifyImage,
        serialNumber: "02",
        title: "Verify",
        paraLine: "Manage fast and easy employee verification",
        listData: [
            "AI driven automatic & super-fast verification process for employees",
            "Flexible solution to adapt to ever-changing environments, laws, and business processes",
            "Easy customisation as per needs",
            "Gain multilayered security and integrity through our HRMS"
        ],
        button: "Learn more"
    },
    {
        image: hrmsAttendance,
        serialNumber: "03",
        title: "Attendance",
        paraLine: "Prioritize employee experience by making their work easier",
        listData: [
            "Facilities biometric attendance preventing proxy",
            "Scheduling shifts and tracking the work hours",
            "Group attendance available that works without internet",
            "Enables the remote employees to check in from anywhere"
        ],
        button: "Learn more"
    },
    {
        image: hrmsOnboard,
        serialNumber: "04",
        title: "Onboard",
        paraLine: "Decrease manual workload to make everything seamless for your workforce",
        listData: [
            "Keep track of all current and finished projects, and access all related information whenever possible.",
            "Unmatched efficiency with our technology-driven record-keeping techniques",
            "From hire to retire, store every employee detail - promotions, transfers and salary",
            "For distance-based mass hiring, our API works for single and bulk hiring"
        ],
        button: "Learn more"
    },
    {
        image: hrmsUpSkill,
        serialNumber: "05",
        title: "Upskill",
        paraLine: "Digitization is the need of the hour that upskills the workforce's capabilities.",
        listData: [
            "More detailed performance reports improve your retention, engagement, and performance levels",
            "Sync up your organizational, team, and personal objectives with your corporate plan",
            "Give honest feedback, commend accomplishments, and assist staff in becoming efficient",
            "Determine how to make the most of your talent pool and organize your development initiatives with the help of an integrated calendar"
        ],
        button: "Learn more"
    }
];


export const organisationsContent = {
    title: {
        normal: "Organizations ",
        coloured: "that trust us ",
        normalContinuation:"to manage their frontline workforce"
    },
    companies: [
        {
            image: flex,
            name:"flex"
        },
        {
            image: foxConn,
            name:"fox conn"
        },
        {
            image: jio,
            name:"jio"
        },
        {
            image: toshiba,
            name:"toshiba"
        },
        {
            image: jabil,
            name:"jabil"
        }
    ]
}