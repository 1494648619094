import React from 'react';
import arrow from "../../images/arrow.svg";
import solutionGradient from "../../images/solutionGradient.svg";

export const Solution = ({ image, serialNumber, title, paraLine, listData,index }) => {
    // console.log(image);
    const isOdd = index % 2 !== 0;
  return (
      <div className={`flex flex-wrap justify-center items-center ${isOdd && "flex-row-reverse"} my-3 `}>
          <div className={`w-full lg:w-6/12 xl:6/12 pl-8 md:pl-10 ${!isOdd ? "pl-8 md:pl-10 pr-8 md:pr-0":"pr-8 md:pr-8 "}`}>
              <div className='flex'>
                  <h4 className='text-[100px] xl:text-[154px]  font-[700] text-[#2C1E40]'>{serialNumber}</h4>
                  <div className='pt-10 ml-4'>
                      <h3 className='md:text-[25px] xl:text-[54px] '>{title}</h3>
                      <p className='text-[#887a9d] mt-4'>{paraLine}</p>
                  </div>
              </div>
                    <div className='xl:pl-[200px] mt-4 md:mt-0'>
                  {
                      listData.map((data, index) => {
                          return (
                              <div className='flex pb-8 w-full' key={index}>
                                  <img src={arrow} alt="arrow" className=''/>
                                  <p className='ml-4 md:text-[17px]'>{data}</p>
                              </div>
                          )
                      })
                  }
              </div>
              
          </div>
          <div className='w-full lg:w-6/12 xl:6/12 relative overflow-hidden'>
              <p></p>
              <img src={solutionGradient} alt="gradient" className='w-full'/>
              {/* <img src={image} alt={ title} className="relative" /> */}
               <img src={image} alt={title} className="absolute top-[10%] left-[10%] w-[90%] object-cover"/>
          </div>
    </div>
  )
}
