import React from 'react'
import { solutions } from '../../data/hrmsContent'
import { Solution } from './Solution'

export const HrmsSolutions = () => {
  return (
      <section className='py-4 w-full '>
          {
              solutions.map((solution, index) => {
                  return (
                      <Solution {...solution} key={index} index={index} />
                  )
              })
          }
    </section>
  )
}
