import React from 'react'
import { HRMSPage } from '../components/HRMS/HRMSPage';
import { Layout } from '../components/Layout'
import "../components/index.css";

const HRMS = () => {
    return (
        <div>
            <Layout>
                <HRMSPage />
            </Layout>
        </div>
    )
}


export default HRMS;